import axios, { AxiosRequestConfig } from 'axios';

const ApiBaseUrl = process.env.NEXT_PUBLIC_FREE_API_URL || process.env.NEXT_PUBLIC_API_URL;
let ApiToken: string | undefined;

export const setToken = (token?: string) => {
    ApiToken = token;
}

const axiosInstance = axios.create({
    baseURL: ApiBaseUrl
});

axiosInstance.interceptors.request.use((config: any) => {
    if (ApiToken && config.headers) {
        config.headers.Authorization = `Bearer ${ApiToken}`;
    }

    if (typeof window === 'undefined') {
          config.headers = {
            ...config.headers,
            Accept: 'application/json',
            'Accept-Encoding': 'identity'
          }
    }
    
    return config;
});

export default axiosInstance;