import React, { useState, useEffect, useMemo } from "react";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/nextjs";
import { UserProfile, UserProvider, useUser } from '@auth0/nextjs-auth0/client';
import { UserRole } from "@jconradi/tutu-porn-api-client/src/users";
import { setToken } from "@jconradi/tutu-porn-api-client/src/axios";
import { SilentAuth } from "../components/Auth/SilentAuth";

interface JwtData extends UserProfile {
    username: string;
    email: string;
    role?: UserRole;
    id: string;
    accessToken: string;
}

export interface AuthContextType {
    jwtData?: JwtData;
    isLoading: boolean;
    isLoggedIn: boolean;
}
export const AuthContext = React.createContext<AuthContextType>({
    isLoading: true,
    isLoggedIn: false
});

export const AuthContextProvider = (props: any) => {
    const auth0User = useUser();

    const jwtData = useMemo(() => {
        if (!auth0User.isLoading) {
            if (auth0User.user) {
                const jwtData = auth0User.user as JwtData;
                setToken(jwtData.accessToken)
                Sentry.setUser({
                    username: jwtData.username as string,
                    email: jwtData.email!,
                    id: jwtData.id
                });

                return jwtData;
            }
            else {
                setToken(undefined);
                Sentry.setUser(null);
                return undefined;
            }
        }
    }, [auth0User.isLoading, auth0User.user]);


    const onWindowMessage = async (event: any) => {
        if (event.data == 'sso_silent_refresh') {
            await auth0User.checkSession();
        }
    };

    useEffect(() => {
        addEventListener('message', onWindowMessage);

        return () => {
            removeEventListener('message', onWindowMessage);
        }
    }, []);

    const value = {
        jwtData,
        isLoading: auth0User.isLoading,
        isLoggedIn: !!auth0User.user
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
}