const { languages } = require('@jconradi/tutu-porn-components/util/languages');

module.exports = {
    "locales": Object.keys(languages),
    "defaultLocale": "en",
    languages,
    "pages": {
        "*": [
            "common"
        ],
        "/login": ["auth"],
        "/signup": ["auth"],
        "/users/[username]": ["users"],
        "/user/profile": ["settings"],
        "/create": ['create']
    },
    logBuild: process.env.NODE_ENV == 'development',
}