
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.css'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { Poppins } from '@next/font/google';
import { ReactElement, ReactNode, useEffect } from 'react';
import { NextPage } from 'next';
import { AuthContextProvider } from '@jconradi/tutu-porn-components/contexts/AuthContext';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GoogleAnalytics } from "nextjs-google-analytics";
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { useRouter } from 'next/router';

const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '600', '700'],
  display: "block"
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000), // 5 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    }
  }
})

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const app = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page) => page);

  return <main className={poppins.className}>
    <GoogleAnalytics trackPageViews />
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <QueryParamProvider adapter={NextAdapter} options={{
          enableBatching: true
        }}>
          <UserProvider>
            <AuthContextProvider>
              {getLayout(
                <Component {...pageProps} />
              )}
            </AuthContextProvider>
          </UserProvider>
        </QueryParamProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </Hydrate>
    </QueryClientProvider>
  </main>
}

const __Page_Next_Translate__ = app;

// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  